// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Your Google Analytics Tracking ID
const GA_TRACKING_ID = 'G-B7BG7CPZ2G';

/**
 * Function to load the Google Analytics script dynamically
 */
const loadGoogleAnalytics = () => {
  return new Promise((resolve) => {
    // Check if GA script is already present
    if (!document.querySelector(`script[src="https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}"]`)) {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
      script.async = true;
      script.onload = () => {
        initializeGA();
        resolve();
      };
      document.head.appendChild(script);
    } else {
      // If script is already present, just initialize GA
      initializeGA();
      resolve();
    }
  });
};

/**
 * Function to initialize GA after the script has loaded
 */
const initializeGA = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag(){window.dataLayer.push(arguments);}
  window.gtag = gtag;
  gtag('js', new Date());
  gtag('config', GA_TRACKING_ID, {
    page_path: window.location.pathname,
  });
};

/**
 * Function to send a page view event to GA
 */
const sendPageView = () => {
  if (window.gtag) {
    window.gtag('event', 'page_view', {
      page_path: window.location.pathname,
    });
  }
};

/**
 * Function to perform the redirect after ensuring GA has been initialized
 */
const performRedirect = () => {
  sendPageView();

  // Delay the redirect slightly to allow GA to send the event
  setTimeout(() => {
    window.location.replace('https://apps.shopify.com/clean-sweeps');
  }, 100); // 100ms delay
};

/**
 * Main function to initialize GA and handle rendering or redirecting
 */
const init = async () => {
  await loadGoogleAnalytics();

  if (window.location.pathname === '/') {
    performRedirect();
  } else {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
};

// Initialize the app
init();

reportWebVitals();
