// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Header />

        {/* Add RouteChangeTracker */}
        <RouteChangeTracker />

        {/* Define Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/policy" element={<PrivacyPolicy />} />
        </Routes>

        {/* Add Footer */}
        <Footer />
      </div>
    </Router>
  );
}

function RouteChangeTracker() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-B7BG7CPZ2G', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return null;
}

export default App;
